<template>
  <v-container class="home" fluid id="treatments">
    <v-row class="text-center mt-12 mb-6">
      <v-col>
        <h2 class="text-h3 grey--text text--darken-4">Wat heb ik te bieden?</h2>
        <h3 class="subtitle-1 font-style-body grey--text text--darken-2">Ik kan je op verschillende manieren helpen</h3>
      </v-col>
    </v-row>
    <Treatments/>
    <Practice id="practice"/>
    <WhoAmI id="who-am-i"/>
    <HomeVideo/>
    <Contact id="contact"/>
  </v-container>
</template>

<script>

import Treatments from '@/components/home/Treatments.vue';
import WhoAmI from '@/components/home/WhoAmI.vue';
import Practice from '@/components/home/Practice.vue';
import Contact from '@/components/home/Contact.vue';
import HomeVideo from '@/components/home/HomeVideo.vue';
export default {
  name: 'Home',
  components: { HomeVideo, Contact, Practice, WhoAmI, Treatments },
  watch: {
    '$route.name'(name) {
      this.$vuetify.goTo(`#${name}`, {offset: 0, duration: 300});
    }
  },
  mounted() {
    this.$vuetify.goTo(`#${this.$route.name}`, {offset: 0, duration: 0});
  }
};
</script>
