<template>
  <div>
    Pijn omdat je de hele dag achter je computer zit, zware spullen hebt getild of lange tijd in een bepaalde houding je werkzaamheden uitvoert... Ik kan je helpen door samen met jou te kijken hoe je dit op een minder belastende manier kunt doen. Klachten die je kunt ontwikkelen vanuit een belastende houding kunnen zich ontwikkelen in de lage rug, heup, knie en voet, arm, nek of schouders en hoofd.<br />
    Deze klachten kunnen zich uiten pijnklachten en / of stijfheid.<br />
    <br />
    Ook vanuit lichamelijke afwijkingen en ziekten van het steun- en bewegingsapparaat kun je klachten ontwikkelen, zogenaamde <span class="font-weight-bold">orthopedische klachten.</span><br />
    Hierbij moet je denken aan afwijkingen in de wervelkolom, standsafwijkingen in de voeten / knieën / heupen en termen als scoliose en artrose. Hier kan ik je ook bij helpen en kijken we samen wat past bij jou voor het verminderen van de klachten en / of pijn.<br />
  </div>
</template>

<script>
export default {
  name: 'Posture',
};
</script>
