<template>
  <div>
    Heb je te maken met emotionele spanningen of een te hoge werkdruk, dan kan dit zich uiten in
    geestelijke en/of lichamelijke klachten. Dit worden ook wel psychosomatische klachten genoemd.
    De klachten zijn herkenbaar of uiten zich vooral door:
    <ul class="mt-6">
      <li>verkeerd ademhalingspatroon;</li>
      <li>ademhalingsinsufficiëntie;</li>
      <li>schouder- en nekklachten;</li>
      <li>spierspanningshoofdpijn;</li>
      <li>duizeligheid;</li>
      <li>aangezichtsklachten;</li>
      <li>vermoeidheid;</li>
      <li>prikkelbaarheid;</li>
      <li>maag- en buik problemen;</li>
      <li>slaapproblemen.</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Stress',
};
</script>

<style scoped>

</style>
