<template>
  <v-row style="height: 80vh;">
    <v-img :src="require('@/assets/images/inside-practicenew.jpg')">
      <v-row class="fill-height justify-center align-center "
             no-gutters
             style="background-color: rgba(255,255,255,0.25)">

        <v-col class="text-center" cols="3" v-if="$vuetify.breakpoint.mdAndUp">
          <v-img
              :src="require('@/assets/images/practice.jpg')"
              class="ml-10"
              style="transform: rotate(-10deg); border: 10px solid white;"
          ></v-img>

          <v-img
              :src="require('@/assets/images/waitingroom.jpg')"
              class="ml-6"
              style="transform: rotate(2deg); border: 10px solid white;"
          ></v-img>

        </v-col>
        <v-col class="text-center pr-6" cols="12" offset-md="2" md="6">
          <h3 class="text-md-h3 mb-6 text-h5">Welkom in de praktijk.</h3>
          <v-btn
              class="ma-3"
              color="black"
              href="https://maps.google.com/?q=Dukaat 1a, 5283 PJ Boxtel"
              outlined
              rel="noopener noreferrer"
              target="_blank"
              x-large>
            Route beschrijving
          </v-btn>
        </v-col>
      </v-row>
    </v-img>
  </v-row>
</template>

<script>
export default {
  name: 'Practice',
};
</script>

<style scoped>

</style>
