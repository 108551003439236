<template>
  <v-row align-md="center" class="blue-grey darken-2 white--text py-16 who-am-i" justify="center">
    <v-col cols="12" offset-md="1" sm="4" v-if="$vuetify.breakpoint.mdAndUp">
      <VImg :src="require('@/assets/images/photo_inge.jpg')"
            :lazy-src="require('@/assets/images/photo_inge_lazy.jpg')"
            width="300px"/>
    </v-col>
    <v-col cols="12" md="4" sm="10">
      <h2 class="text-h3 mb-3">Wie ben ik?</h2>
      <p>
        <VImg :src="require('@/assets/images/photo_inge.jpg')"
              :lazy-src="require('@/assets/images/photo_inge_lazy.jpg')"
              width="100px"
              class="float-right"
              v-if="$vuetify.breakpoint.smAndDown"/>
        Ik ben Inge Heesterbeek – de Groot, al meer dan 25 jaar oefentherapeut Mensendieck.<br/>
        Als oefentherapeut geef ik mensen inzicht in hun lichaam en waar hun klachten vandaan komen.<br/>
        Door bewustmaken hoe dit bij jou functioneert en hoe dit minder belastend kan. Hierdoor komt het lichaam
        beter in balans, vermindert de overbelasting en worden de klachten minder en/of verdwijnen.<br/>
        Iedere persoon heeft dan ook een eigen unieke aanpak.<br/>
        Ik geef je mijn kennis, ervaring en de tools om anders en bewuster met je lichaam om te gaan.<br/>
        Hiermee krijg je van mij de sleutel voor succes in eigen handen.<br/>
      </p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'WhoAmI',
};
</script>

<style lang="scss">
.who-am-i.row {
  min-height: 80vh;
  position:   relative;

  [class*="col-"] {
    z-index: 1;
  }

  &::before {
    z-index:    0;
    content:    "";
    position:   absolute;
    top:        0;
    left:       0;
    width:      80%;
    height:     100%;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #455a64 90%), url("../../assets/images/patern.png");
  }
}
</style>
