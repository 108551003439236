<template>
  <v-dialog
      v-model="modal"
      max-width="750"
      eager
      transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title>{{title}}</v-card-title>
      <VImg :src="require(`@/assets/icon-${icon}.svg`)" class="float-right ma-6 mt-n2" height="150" width="150"/>
      <v-card-text style="min-height: 200px">
        <component :is="content"/>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'TreatmentDialog',
  props: {
    title: {
      type: String,
      required: true,
    },
    content: {
      type: Object,
      required: true,
    },
    open: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      type: String,
      required: true,
    }
  },
  data: () => ({
    modal: false,
  }),
  watch: {
    modal() {
      this.$emit('update:open', this.modal);
    },
    open() {
      this.modal = this.open;
    },
  },
};
</script>
