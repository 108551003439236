<template>
  <v-parallax :lazy-src="require('@/assets/images/contact_image_lazy.jpg')"
              :src="require('@/assets/images/contact_image_full_hd.jpg')"
              class="ma-n3"
              width="100%">
    <v-row class="justify-center align-center white--text">
      <v-col class="text-center">
        <h3 class="text-md-h3 mb-6 text-h5">Heb je vragen, neem gerust contact met mij op!</h3>
        <v-btn
            class="ma-3"
            color="white"
            outlined
            rel="noopener noreferrer"
            target="_blank"
            href="tel:0411671456"
            x-large>
          0411 67 14 56
        </v-btn>
        <v-btn
            class="ma-3"
            color="white"
            href="mailto:info@mensendieckboxtel.nl"
            outlined
            rel="noopener noreferrer"
            target="_blank"
            x-large>
          info@mensendieckboxtel.nl
        </v-btn>
      </v-col>
    </v-row>
  </v-parallax>
</template>

<script>
export default {
  name: 'Contact',
};
</script>

<style lang="scss" scoped>
::v-deep {
  img {
    filter: brightness(0.5);
  }
}
</style>
