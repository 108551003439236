<template>
  <div>
    Ik kan je helpen met alle klachten die voortkomen uit een verstoord slaapritme.
    Dit doe ik onder andere door cognitieve gedragstherapie, de cirkel te doorbreken en
    terug te keren tot gewenst en gezond slaapgedrag.
    Met als resultaat minder fysieke of mentale problemen.
  </div>
</template>

<script>
export default {
  name: 'Sleep',
};
</script>

<style scoped>

</style>
