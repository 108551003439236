<template>
  <div>
    Dit zijn chronische ontstekingsziekten, aandoeningen die voortkomen uit Reumatoïde artritis.
    De ziekte tast vooral gewrichten en de lichaamsstructuren daaromheen aan en gaat niet meer over
    (chronisch).
    Je kunt hierbij last krijgen van pijn, zwelling en stijfheid in gewrichten, ochtendstijfheid,
    vermoeidheid, minder spierkracht, slechtere conditie.
    Samen kunnen we kijken welke bewegingen/oefeningen je kunnen helpen om hier zo min mogelijk
    last van te hebben en het functioneren te verbeteren.
  </div>
</template>

<script>
export default {
  name: 'Joint',
};
</script>

<style scoped>

</style>
