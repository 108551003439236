<template>
  <v-row class="treatments justify-space-around px-lg-16 mb-16">
    <v-col v-for="(item, index) in items"
           :key="index"
           class="text-center mb-12 px-6 px-lg-16 px-xl-6"
           cols="12"
           lg="4"
           md="4"
           sm="6"
           xl="2">
      <VImg :src="require(`@/assets/icon-${item.icon}.svg`)" class="ma-auto mb-6" height="100" width="100"/>
      <h4 class="text-h6 mb-3">{{ item.title }}</h4>
      <p v-html="item.teaser"></p>
      <v-btn color="primary" text @click="item.open = true">Lees meer</v-btn>
      <TreatmentDialog :content="item.content" :open.sync="item.open" :title="item.title" :icon="item.icon"/>
    </v-col>
  </v-row>
</template>

<script>
import TreatmentDialog from '@/components/TreatmentDialog.vue';
import Posture from '@/components/treatments/Posture.vue';
import Stress from '@/components/treatments/Stress.vue';
import Neuro from '@/components/treatments/Neuro.vue';
import Joint from '@/components/treatments/Joint.vue';
import Sleep from '@/components/treatments/Sleep.vue';

export default {
  name: 'Treatments',
  components: { TreatmentDialog },
  data() {
    return {
      activeTreatment: 0,
      items: [
        {
          title: 'Houdingsgerelateerd',
          teaser: 'Zitten, staan, tillen, liggen... <br />Heb of krijg je hierdoor pijn...',
          content: Posture,
          icon: 'spine',
          open: false,
        },
        {
          title: 'Spanningsgerelateerd',
          teaser: 'Emotionele stress of een te hoge werkdruk? Welke klachten passen hier bij en hoe herken ik ze...',
          content: Stress,
          icon: 'stress',
          open: false,
        },
        {
          title: 'Neurologische klachten',
          teaser: 'Ook ons zenuwstelsel kan zorgen voor klachten. Hoe herken ik deze en wat kan ik er aan doen...',
          content: Neuro,
          icon: 'neuro',
          open: false,
        },
        {
          title: 'Reumatische klachten',
          teaser: 'Beter functioneren met een chronische ziekte... Wat houdt dit in en wat kan ik voor je betekenen.',
          content: Joint,
          icon: 'joint',
          open: false,
        },
        {
          title: 'Slaapoefentherapie',
          teaser: 'Slaap je slecht, is je ritme verstoord? Samen kijken we hoe dit patroon te doorbreken…',
          content: Sleep,
          icon: 'sleep',
          open: false,
        },
      ],
    };
  },
};
</script>
