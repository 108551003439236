<template>
  <v-row
      v-intersect="onIntersect" class="primary">
    <div class="video-container">
      <VImg :src="require('@/assets/images/video_leader.png')" v-if="isLeaderVisible" width="20%" class="leader"/>
      <div
          v-show="!isLeaderVisible">
        <youtube
            :nocookie="true"
            video-id="Z2PmPSZC79E"
            ref="youtube"
            height=""
            width=""
            @ended="onVideoEnded"
            @playing=onVideoStarted
            :player-vars="{mute:1}"
        />
      </div>
    </div>
  </v-row>
</template>

<script>
export default {
  name: "HomeVideo",
  data: () => ({
    videoIsPlaying: false,
    isLeaderVisible: true,
  }),
  methods: {
    onVideoEnded() {
      this.isLeaderVisible = true;
      setTimeout(() => {
        this.$refs.youtube.playVideo();
      }, 1000);
    },
    onVideoStarted() {
      setTimeout(() => {
        this.isLeaderVisible = false;
      }, 2000);
    },
    onIntersect(entries) {
      const isIntersecting = entries[0].isIntersecting;
      if (!isIntersecting) return;

      return this.$refs.youtube.player.playVideo();
    },
  },
};
</script>

<style>
.video-container {
  pointer-events: none;
  position:       relative;
  width:          100%;
  padding-bottom: 56.25%;
  margin-bottom:  12px;
}
.leader {
  width: 20%;
  position: absolute;
  left: 50%;
  margin-left: -10%;
  top: 50%;
  margin-top: -10vw;
}
.video-container iframe {
  filter:   hue-rotate(-256deg);
  position: absolute;
  top:      0;
  left:     0;
  width:    100%;
  height:   100%;
  border:   0;
}
</style>
