<template>
  <div>
    Neurologische klachten komen voort uit beschadigingen van het zenuwstelsel (het centrale of het
    perifere zenuwstelsel).
    Bij neurologische klachten kan ik je helpen met het verminderen van de pijn en het verbeteren van je
    dagelijkse functioneren. Hierbij moet je denken aan klachten zoals…
    <v-row class="mt-6">
      <v-col cols="6">
        <ul>
          <li>Krachtsverlies</li>
          <li>Spasticiteit</li>
          <li>Coördinatiestoornissen</li>
          <li>Verlammingen</li>
          <li>Evenwichtsstoornissen</li>
          <li>Spierverkortingen</li>
          <li>Gewrichtsverstijvingen</li>
          <li>Gevoelsstoornissen en concentratiestoornissen</li>
        </ul>
      </v-col>
      <v-col cols="6">
        <ul>
          <li>Hernia (HNP)</li>
          <li>Migraine/hoofdpijnklachten</li>
          <li>Uitstralende klachten in de arm (Cervico- brachialgie)</li>
          <li>Uitstralende klachten in het been (Ischias klachten)</li>
          <li>Whiplash</li>
          <li>Ziekte van Parkinson</li>
          <li>Multiple Sclerose (MS)</li>
          <li>CVA en spierziekten</li>
        </ul>
      </v-col>

    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Neuro',
};
</script>

<style scoped>

</style>
